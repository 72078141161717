import React from 'react'
import '../Styles/Parcel.scss'
import Hero from '../Hero'
import ProblemStatement from '../Problem'
import ProxyPNG from '../Styles/parcel.png'


const Parcel = () => {
  return (
    <div className='parcel-container'>
<div className="main-parcel-content">
        <div className="main-left-section">
          <h1><strong>Project Overview</strong></h1>
          <div className="main-parcel-product">
            <h2><strong>The Product</strong></h2>
            <p>
            To create a user-centric delivery service that ensures packages can be delivered seamlessly to users, regardless of their current or changing locations. 
            </p>
            <div className="main-project">
              <h2><strong>Project Duration</strong></h2>
              <p>
              Feb - March 2025

              </p>
            </div>
          </div>
        </div>
        <div className="main-right-section">
          <img src={ProxyPNG} alt="img" />

        </div>
      </div>


      <div className="main-parcel-content bg-changer">
        <div className="main-left-section">
          {/* <h1><strong>Project Overview</strong></h1> */}
          <div className="main-parcel-product text-changer">
            <h2 className='text-changer'><strong>The Problem</strong></h2>
            <p className='text-changer'>
            For this project revolves around the inconvenience and lack of flexibility in traditional package delivery systems.
            </p>
          </div>
        </div>
        <div className="main-right-section text-changer">
          <div className="main-goal-section">
            <h2><strong>The Goal</strong></h2>
            <p>
            </p>
            The goal for this project is to create a delivery service that prioritize flexibility, security, and convenience for user.
          </div>
        </div>
      </div>

      <div className="main-parcel-content">
        <div className="main-left-section">
          {/* <h1><strong>Project Overview</strong></h1> */}
          <div className="main-parcel-product">
            <h2><strong>My Role</strong></h2>
            <p>
            As the UX designer leading the project, I take ownership of every aspect of the design process, from user research and prototyping to testing and refinement. Leveraging the power of Replit AI, I streamline development, ensure efficiency, and introduce innovative solutions that enhance user experiences while aligning with project goals and objectives.
            </p>
          </div>
        </div>
        <div className="main-right-section">
          <h2><strong>Responsibilies</strong></h2>
          <p>
          Conducting interviews, paper and digital wireframing, low and high-fidelity prototyping, conducting usability studies, accounting for accessibility, iterating on designs and responsive design.
          </p>
        </div>
      </div>


      <div className="main-parcel-content bg-changer">
        <div className="main-left-section">
          <h1><strong>Understanding The User</strong></h1>
          <div className="main-parcel-product">
            <h2><strong></strong></h2>
            <p>

            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="text-changer">
            <h2><strong>User Research</strong></h2>
            <h2><strong>Personas</strong></h2>
            <h2><strong>Problems Statements</strong></h2>
            <h2><strong>User Journey Maps</strong></h2>

          </div>
        </div>
      </div>

      <div className="main-parcel-content">
        <div className="main-left-section">
          <h1><strong>User Research: Summary</strong></h1>
          <div className="main-parcel-product">
            <h2><strong></strong></h2>
            <p>
            For this project, we conducted a combination of user interviews, surveys, and usability tests to explore user behaviors, pain points, and preferences regarding flexible package delivery systems. Initially, we assumed that users primarily valued delivery speed above all else. However, the research revealed that flexibility and security are just as critical, especially for frequent travelers and individuals with dynamic schedules. The findings shifted our focus toward features like real-time address updates, transparent tracking, and secure package handover to meet these deeper user needs effectively.
            </p>
          </div>
        </div>
      </div>

      <div className="main-parcel-content text-changer bg-changer">
        <div className="main-left-section">
          <h1><strong>User Research: Pain Points</strong></h1>
          <div className="main-parcel-pain-point">
            <h2><strong>Pain Point</strong></h2>
            <p>
            Inflexibility in Delivery Locations
Users are limited to setting a fixed address during checkout, which doesn’t accommodate dynamic or mobile lifestyles

            </p>
          </div>
          <div className="main-parcel-pain-point">
            <h2><strong>Pain Point</strong></h2>
            <p>
            Missed Deliveries
Many users experience frustration when packages are delivered while they are not present at the specified location, leading to delays and rescheduling

            </p>
          </div>
          <div className="main-parcel-pain-point">
            <h2><strong>Pain Point</strong></h2>
            <p>
            Lack of Real-Time Updates
Users often find it challenging to track packages accurately or make modifications to the delivery location or timing

            </p>
          </div>
          <div className="main-parcel-pain-point">
            <h2><strong>Pain Point</strong></h2>
            <p>
            Security Concerns
There is a significant worry about package being delivered to incorrect individuals or left in unsecured areas, leading to mistrust in the delivery process

            </p>
          </div>
        </div>
      </div>

      <div className="main-parcel-content ">
        <div className="main-left-section">
          <h1 cka><strong>Persona: David</strong></h1>
          <div className="main-parcel-product ">
            <h2 className=''><strong>Problem Statement</strong></h2>
            <p className=''>
            [User name] is a [user characteristics] 
who needs [user need] 
because [insight].

            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <h2 className=''><strong>David Smith</strong></h2>
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>


      <div className="main-parcel-content">
        <div className="main-left-section">
          <h1><strong>User Journey Map</strong></h1>
          <div className="main-parcel-product">
          <h2 className=''><strong>Problem statement:</strong></h2>

            <p>
            Alex Johnson, a digital nomad, struggles with missed deliveries and a lack of flexibility in traditional delivery systems. They need a service that provides real-time address updates, secure handovers, and reliable tracking to match their dynamic lifestyle.
            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>


      <div className="main-parcel-content bg-changer">
        <div className="main-left-section">
          <h1><strong>Starting The Design</strong></h1>
          <div className="main-parcel-product">
            <h2><strong></strong></h2>
            <p>

            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="text-changer">
            <h2><strong>Sitemap</strong></h2>
            <h2><strong>Paper Wireframes</strong></h2>
            <h2><strong>Digital Wireframes</strong></h2>
            <h2><strong>Low-fidelity Prototype</strong></h2>
            <h2><strong>Usability Studies</strong></h2>
          </div>
        </div>
      </div>


      <div className="main-parcel-content">
        <div className="main-left-section">
          <h1><strong>Paper Wireframes</strong></h1>
          <div className="main-parcel-product">
            <h2><strong></strong></h2>
            <p>
            [Your notes about goals and thought process]
            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>


      <div className="main-parcel-content bg-changer">
        <div className="main-left-section">
          <h1><strong>Paper Wireframes screeen size variatoin(s)</strong></h1>
          <div className="main-parcel-product">
            <h2><strong></strong></h2>
            <p className='text-changer'>I drafted iterations of each screen on paper.</p>
            <p className='text-changer'>
              I also started to work on designs for additional screen sizes to make sure the site would be fully responsive.
            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>

      <div className="main-parcel-content">
        <div className="main-left-section">
          <h1><strong>Sitemap</strong></h1>
          <div className="main-parcel-product">
            <h2><strong></strong></h2>
            <p>
            [Your notes about goals and thought process]

            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>


      <div className="main-parcel-content bg-changer">
        <div className="main-left-section">
          <h1 className='text-changer'><strong>Digital Wireframes</strong></h1>
          <div className="main-parcel-product">
            <h2><strong></strong></h2>
            <p className='text-changer'>
              Using wireframes, I put my ideas on paper first and then started to make high-fidelity wireframes.
              After dozens of iterations, these are the wireframes that best represented user flow and met user needs.

            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>

      <div className="main-parcel-content">
        <div className="main-left-section">
          <h1><strong>Digital Wireframes screen size variations(s)</strong></h1>
          <div className="main-parcel-product">
            <h2><strong></strong></h2>
            <p>
            [Your notes about goals and thought process + how you responded to and implemented peer feedback]
            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>

      <div className="main-parcel-content bg-changer">
        <div className="main-left-section">
          <h1 className='text-changer'><strong>Low-fedility Prototype</strong></h1>
          <div className="main-parcel-product">
            <h2><strong></strong></h2>
            <p className='text-changer'>
              I created a low-fidelity prototype from the user flow diagram and wireframes to test functionality before incorporating it into final design and ensure accessibility for end-userss.
            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>


      <div className="main-parcel-content">
        <div className="main-left-section">
          <h1><strong>Usability Study: Parameters</strong></h1>
          <div className="main-parcel-pain-point">
            <h2><strong>Study Type:</strong></h2>
            <p>
            Unmoderated usability study
            </p>
          </div>
          <div className="main-parcel-pain-point">
            <h2><strong>Location:</strong></h2>
            <p>
            United States, remote
            </p>
          </div>
          <div className="main-parcel-pain-point">
            <h2><strong>Participants:</strong></h2>
            <p>
            5 participants
            </p>
          </div>
          <div className="main-parcel-pain-point">
            <h2><strong>Length:</strong></h2>
            <p>
            20-30 minutes

            </p>
          </div>
        </div>
      </div>


      <div className="main-parcel-content bg-changer">
        <div className="main-left-section">
          <h1 className='text-changer'><strong>Usability Study: Findings</strong></h1>
          <div className="main-parcel-pain-point">
            <h2 className='text-changer'><strong>Finding</strong></h2>
            <p className='text-changer'>
            Flexibility Challenges
Users found it difficult to update their delivery address after placing an order, leading to frustration when their plans or locations changed

            </p>
          </div>
          <div className="main-parcel-pain-point">
            <h2 className='text-changer'><strong>Finding</strong></h2>
            <p className='text-changer'>
            Tracking Issues
Participants exposed a need for more transparent, real-time tracking that showed both package’s location and estimated time of delivery

            </p>
          </div>
          <div className="main-parcel-pain-point">
            <h2 className='text-changer'><strong>Finding</strong></h2>
            <p className='text-changer'>
            Security Concerns
Users were concerned about packaged handover, highlighting the need for secure delivery features such as PIN verification or proof of delivery photos

            </p>
          </div>
        </div>
      </div>

      <div className="main-parcel-content ">
        <div className="main-left-section">
          <h1><strong>Refining The Design</strong></h1>
          <div className="main-parcel-product">
            <h2><strong></strong></h2>
            <p>

            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <h2><strong>Mockups</strong></h2>
            <h2><strong>High-fedility Prototype</strong></h2>
            <h2><strong>Accessibility</strong></h2>
          </div>
        </div>
      </div>


      <div className="main-parcel-content bg-changer">
        <div className="main-left-section">
          <h1><strong>Mockups</strong></h1>
          <div className="main-parcel-product">
            <h2><strong></strong></h2>
            <p className='text-changer'>
              [Your notes about goals and thought process + how you responded to and implemented peer feedback]
            </p>
          </div>
          Before
          <img src={ProxyPNG} alt="img" />\
          After
          <img src={ProxyPNG} alt="img" />
        </div>
      </div>


      <div className="main-parcel-content">
        <div className="main-left-section">
          <h1><strong>Mockup: Original screen size</strong></h1>
          <div className="main-mocks">
            <img src={ProxyPNG} alt="img" />
            {/* <img src={ProxyPNG} alt="img" />
            <img src={ProxyPNG} alt="img" />
            <img src={ProxyPNG} alt="img" /> */}
          </div>
        </div>
      </div>

      <div className="main-parcel-content bg-changer">
        <div className="main-left-section">
          <h1><strong>High-Fedility Prototype</strong></h1>
          <div className="main-parcel-product">
            <h2><strong></strong></h2>
            <p className='text-changer'>
              After finalizing the low-fidelity prototype. I worked on creating the final designs with the goal of making them simple and intuitive. The main color theme i used was blue because it’s a very powerful color that symbolizes strength, power, and action. I thought it was very representative of the persona’s character.
            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>



      <div className="main-parcel-content">
        <div className="main-left-section">
          <h1><strong>Accessiblity Considerations</strong></h1>
          <div className="main-parcel-pain-point">
            <h2><strong>1</strong></h2>
            <p>
            Clear Navigation
The interface is designed with intuitive navigation and clear labels to accommodate users of varying technical abilities

            </p>
          </div>
          <div className="main-parcel-pain-point">
            <h2><strong>2</strong></h2>
            <p>
            Assistive Technology
The platform is fully compatible with screen readers and voice commands to ensure inclusivity for users with visuals physical impairments

            </p>
          </div>
          <div className="main-parcel-pain-point">
            <h2><strong>3</strong></h2>
            <p>
            Contrast and Text Size
High-contrast color schemes and adjustable text sizes are implemented to improve readability for all users, including those with visual impairments

            </p>
          </div>
        </div>
      </div>

      <div className="main-parcel-content bg-changer">
        <div className="main-left-section">
          <h1><strong>Going Forward</strong></h1>
          <div className="main-parcel-product">
            <h2><strong></strong></h2>
            <p>

            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="text-changer">
            <h2><strong>Takeaways</strong></h2>
            <h2><strong>Next Steps</strong></h2>
          </div>
        </div>
      </div>

      <div className="main-parcel-content">
        <div className="main-left-section">
          <h1><strong>Takeaways</strong></h1>
          <div className="main-parcel-product">
            <h2><strong>Impact</strong></h2>
            <p>
            The designs received positive feedback, with a study participant stating, "The flexibility and real-time tracking features solve such a common issue—this would make my life so much easier!" This highlights the potential of the designs to address user pain points effectively and improve delivery experiences.
            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <h2><strong>What I learned</strong></h2>
            <p>
            I learned the importance of prioritizing user flexibility and security in delivery services. Understanding user pain points, such as missed deliveries and lack of real-time updates, highlighted the need for adaptable and transparent solutions. This process deepened my appreciation for user-centered design and reinforced how thoughtful features can significantly improve user satisfaction and trust.
            </p>
          </div>
        </div>
      </div>

      <div className="main-parcel-content bg-changer">
        <div className="main-left-section">
          <h1><strong>Next Steps</strong></h1>
          <div className="main-parcel-pain-point">
            <h2 className='text-changer'><strong>1</strong></h2>
            <p className='text-changer'>
            The next steps for this project would involve conducting additional usability tests to refine key features like real-time address updates and secure delivery verification. 
            </p>
          </div>
          <div className="main-parcel-pain-point">
            <h2 className='text-changer'><strong>2</strong></h2>
            <p className='text-changer'>
            Based on user feedback, we would enhance the interface for even greater accessibility and user-friendliness
            </p>
          </div>
          <div className="main-parcel-pain-point">
            <h2 className='text-changer'><strong>3</strong></h2>
            <p className='text-changer'>
            Implementing a pilot program would help gather real-world insights to further validate the design and ensure it meets diverse user needs effectively.
            </p>
          </div>
        </div>
      </div>


      <div className="main-parcel-content">
        <div className="main-left-section">
          <h1><strong>Let's connect</strong></h1>
          <div className="main-parcel-pain-point">
            <h2><strong>Feedback</strong></h2>
            <p>
            I would appreciate your thoughts and insights on this topic, as feedback is crucial to further enhancing the findings of this study.
            </p>
          </div>
          <div className="main-parcel-pain-point">
            <h2><strong>Email</strong></h2>
            <p>
            estelo2jz@gmail.com
            </p>
          </div>
          {/* <div className="main-parcel-pain-point">
            <h2><strong>LinkedIn</strong></h2>
            <p>
            https://www.linkedin.com/in/estelo-abellanosa-83a335186/
            </p>
          </div> */}
        </div>
      </div>

    </div>
  )
}

export default Parcel