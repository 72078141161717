import React from 'react'
import Hero from '../Hero'
import ProblemStatement from '../Problem'
import '../Styles/Safeguard.scss'
import ProxyPNG from '../Styles/safeguard.png'


const Safeguard = () => {
  return (
    <div className='safeguard-container'>
      <div className="main-safeguard-content">
        <div className="main-left-section">
          <h1><strong>Project Overview</strong></h1>
          <div className="main-safeguard-product">
            <h2><strong>The Product</strong></h2>
            <p>
            To provide secure, user-friendly delivery options that minimize package theft and enhance customer trust in e-commerce and delivery services.
            </p>
            <div className="main-project">
              <h2><strong>Project Duration</strong></h2>
              <p>
              Dec - Jan 2025
              </p>
            </div>
          </div>
        </div>
        <div className="main-right-section">
          <img src={ProxyPNG} alt="img" />

        </div>
      </div>


      <div className="main-safeguard-content bg-changer">
        <div className="main-left-section">
          {/* <h1><strong>Project Overview</strong></h1> */}
          <div className="main-safeguard-product text-changer">
            <h2 className='text-changer'><strong>The Problem</strong></h2>
            <p className='text-changer'>
            The project aims to address the issue of package theft, which often occurs when deliveries are left unattended. This involves solving problems related to inadequate delivery security options and improving communication to ensure users can retrieve their packages safely and conveniently.
            </p>
          </div>
        </div>
        <div className="main-right-section text-changer">
          <div className="main-goal-section">
            <h2><strong>The Goal</strong></h2>
            <p>
            The goal of the project is to enhance delivery security and user trust by minimizing package theft incidents. This involves designing user-centric solutions, such as secure delivery options and improved communication, to ensure a safer and more satisfying delivery experience.
            </p>
          </div>
        </div>
      </div>

      <div className="main-safeguard-content">
        <div className="main-left-section">
          {/* <h1><strong>Project Overview</strong></h1> */}
          <div className="main-safeguard-product">
            <h2><strong>My Role</strong></h2>
            <p>
            As the UX designer leading the project, I take ownership of every aspect of the design process, from user research and prototyping to testing and refinement. Leveraging the power of Replit AI, I streamline development, ensure efficiency, and introduce innovative solutions that enhance user experiences while aligning with project goals and objectives.

            </p>
          </div>
        </div>
        <div className="main-right-section">
          <h2><strong>Responsibilies</strong></h2>
          <p>
          Conducting interviews, paper and digital wireframing, low and high-fidelity prototyping, conducting usability studies, accounting for accessibility, iterating on designs and responsive design.
          </p>
        </div>
      </div>


      <div className="main-safeguard-content bg-changer">
        <div className="main-left-section">
          <h1><strong>Understanding The User</strong></h1>
          <div className="main-safeguard-product">
            <h2><strong></strong></h2>
            <p>
            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="text-changer">
            <h2><strong>User Research</strong></h2>
            <h2><strong>Personas</strong></h2>
            <h2><strong>Problems Statements</strong></h2>
            <h2><strong>User Journey Maps</strong></h2>

          </div>
        </div>
      </div>

      <div className="main-safeguard-content">
        <div className="main-left-section">
          <h1><strong>User Research: Summary</strong></h1>
          <div className="main-safeguard-product">
            <h2><strong></strong></h2>
            <p>
            For this project, the user research focused on understanding the root causes and impacts of package theft on user satisfaction and trust. Through surveys and interviews, I gathered insights into user behaviors, preferences, and frustrations regarding delivery experiences. The research highlighted key pain points, such as a lack of secure delivery options, inadequate communication about delivery times, and challenges in resolving theft incidents. These findings were pivotal in shaping user personas and journey maps, which informed actionable design solutions to enhance delivery security and satisfaction.
            </p>
          </div>
        </div>
      </div>

      <div className="main-safeguard-content text-changer bg-changer">
        <div className="main-left-section">
          <h1><strong>User Research: Pain Points</strong></h1>
          <div className="main-safeguard-pain-point">
            <h2><strong>Pain Point</strong></h2>
            <p>
            Packages left unattended at delivery locations are at high risk of theft, causing frustration for users
            </p>
          </div>
          <div className="main-safeguard-pain-point">
            <h2><strong>Pain Point</strong></h2>
            <p>
            Users often lack access to secure delivery methods, such as parcel lockers or signature-required deliveries, leaving them with fewer choices to safeguard their packages
            </p>
          </div>
          <div className="main-safeguard-pain-point">
            <h2><strong>Pain Point</strong></h2>
            <p>
            Inadequate notifications about delivery times or the status of packages make it difficult for users to plan retrieval, increasing the risk of theft
            </p>
          </div>
          <div className="main-safeguard-pain-point">
            <h2><strong>Pain Point</strong></h2>
            <p>
            Resolving package theft incidents can be time-consuming and unsatisfactory, further compounding user dissatisfaction
            </p>
          </div>
        </div>
      </div>

      <div className="main-safeguard-content ">
        <div className="main-left-section">
          <h1 cka><strong>Persona: David</strong></h1>
          <div className="main-safeguard-product ">
            <h2 className=''><strong>Problem Statement</strong></h2>
            <p className=''>
            Emily, a working parent, feels anxious about package theft in her neighborhood. She struggles with unreliable delivery options and slow claims processes, leading to frustration and a loss of trust in online shopping. She seeks secure, user-friendly solutions to protect her purchases and maintain peace of mind.
            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <h2 className=''><strong>David Smith</strong></h2>
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>


      <div className="main-safeguard-content">
        <div className="main-left-section">
          <h1><strong>User Journey Map</strong></h1>
          <div className="main-safeguard-product">
            <p>
            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>


      <div className="main-safeguard-content bg-changer">
        <div className="main-left-section">
          <h1><strong>Starting The Design</strong></h1>
          <div className="main-safeguard-product">
            <h2><strong></strong></h2>
            <p>

            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="text-changer">
            <h2><strong>Sitemap</strong></h2>
            <h2><strong>Paper Wireframes</strong></h2>
            <h2><strong>Digital Wireframes</strong></h2>
            <h2><strong>Low-fidelity Prototype</strong></h2>
            <h2><strong>Usability Studies</strong></h2>
          </div>
        </div>
      </div>


      <div className="main-safeguard-content">
        <div className="main-left-section">
          <h1><strong>Paper Wireframes</strong></h1>
          <div className="main-safeguard-product">
            <h2><strong></strong></h2>
            <p>
            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>


      <div className="main-safeguard-content bg-changer">
        <div className="main-left-section">
          <h1><strong>Paper Wireframes screeen size variatoin(s)</strong></h1>
          <div className="main-safeguard-product">
            <h2><strong></strong></h2>
            <p className='text-changer'>I drafted iterations of each screen on paper.</p>
            <p className='text-changer'>
              I also started to work on designs for additional screen sizes to make sure the site would be fully responsive.
            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>

      <div className="main-safeguard-content">
        <div className="main-left-section">
          <h1><strong>Sitemap</strong></h1>
          <div className="main-safeguard-product">
            <h2><strong></strong></h2>
            <p>

            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>


      <div className="main-safeguard-content bg-changer">
        <div className="main-left-section">
          <h1 className='text-changer'><strong>Digital Wireframes</strong></h1>
          <div className="main-safeguard-product">
            <h2><strong></strong></h2>
            <p className='text-changer'>
              Using wireframes, I put my ideas on paper first and then started to make high-fidelity wireframes.
              After dozens of iterations, these are the wireframes that best represented user flow and met user needs.

            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>

      <div className="main-safeguard-content">
        <div className="main-left-section">
          <h1><strong>Digital Wireframes screen size variations(s)</strong></h1>
          <div className="main-safeguard-product">
            <h2><strong></strong></h2>
            <p>
            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>

      <div className="main-safeguard-content bg-changer">
        <div className="main-left-section">
          <h1 className='text-changer'><strong>Low-fedility Prototype</strong></h1>
          <div className="main-safeguard-product">
            <h2><strong></strong></h2>
            <p className='text-changer'>
              I created a low-fidelity prototype from the user flow diagram and wireframes to test functionality before incorporating it into final design and ensure accessibility for end-userss.
            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>


      <div className="main-safeguard-content">
        <div className="main-left-section">
          <h1><strong>Usability Study: Findings</strong></h1>
          <div className="main-safeguard-pain-point">
            <h2><strong>Finding</strong></h2>
            <p>
            Need for Flexible Delivery Arrangements
Features like rescheduling deliveries or providing alternate drop-off instructions were highlighted as essential for improving user convenience and security.

            </p>
          </div>
          <div className="main-safeguard-pain-point">
            <h2><strong>Finding</strong></h2>
            <p>
            Importance of Real-Time Notifications 
Users wanted more precise, real-time updates about delivery status to better plan package retrieval.

            </p>
          </div>
          <div className="main-safeguard-pain-point">
            <h2><strong>Finding</strong></h2>
            <p>
            Strong Desire for Secure Delivery Options
Participants expressed a clear preference for solutions like parcel lockers, smart delivery boxes, or signature-required deliveries to minimize theft risks.

            </p>
          </div>
        </div>
      </div>


      <div className="main-safeguard-content ">
        <div className="main-left-section">
          <h1><strong>Refining The Design</strong></h1>
          <div className="main-safeguard-product">
            <h2><strong></strong></h2>
            <p>

            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <h2><strong>Mockups</strong></h2>
            <h2><strong>High-fedility Prototype</strong></h2>
            <h2><strong>Accessibility</strong></h2>
          </div>
        </div>
      </div>


      <div className="main-safeguard-content bg-changer">
        <div className="main-left-section">
          <h1><strong>Mockups</strong></h1>
          <div className="main-safeguard-product">
            <h2><strong></strong></h2>
            <p className='text-changer'>
              [Your notes about goals and thought process + how you responded to and implemented peer feedback]
            </p>
          </div>
          <img src={ProxyPNG} alt="img" />

        </div>
      </div>


      <div className="main-safeguard-content">
        <div className="main-left-section">
          <h1><strong>Mockup: Original screen size</strong></h1>
          <div className="main-mocks">
            <img src={ProxyPNG} alt="img" />
            {/* <img src={ProxyPNG} alt="img" />wwwwwwwwwwwwwwwwww
            <img src={ProxyPNG} alt="img" />
            <img src={ProxyPNG} alt="img" /> */}
          </div>
        </div>
      </div>

      <div className="main-safeguard-content bg-changer">
        <div className="main-left-section">
          <h1><strong>High-Fedility Prototype</strong></h1>
          <div className="main-safeguard-product">
            <h2><strong></strong></h2>
            <p className='text-changer'>
              After finalizing the low-fidelity prototype. I worked on creating the final designs with the goal of making them simple and intuitive. The main color theme i used was blue because it’s a very powerful color that symbolizes strength, power, and action. I thought it was very representative of the persona’s character.
            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>



      <div className="main-safeguard-content">
        <div className="main-left-section">
          <h1><strong>Accessiblity Considerations</strong></h1>
          <div className="main-safeguard-pain-point">
            <h2><strong>1</strong></h2>
            <p>
            Clear and Inclusive Communication
Ensure that delivery notifications and security options are accessible to users with visual, auditory, or cognitive impairments. Use plain language, screen reader-friendly text, and alternative communication methods (e.g., text-to-speech).

            </p>
          </div>
          <div className="main-safeguard-pain-point">
            <h2><strong>2</strong></h2>
            <p>
            Accessible Delivery Interfaces
Design delivery apps and websites with accessibility in mind, including features like high-contrast visuals, scalable text, keyboard navigation, and compatibility with assistive technologies.

            </p>
          </div>
          <div className="main-safeguard-pain-point">
            <h2><strong>3</strong></h2>
            <p>
            Physical Accessibility of Delivery Solutions
For secure delivery options like lockers or designated drop-off points, ensure they are physically accessible to users with mobility challenges. This includes appropriate heights, clear pathways, and compliance with ADA standards.

            </p>
          </div>
        </div>
      </div>

      <div className="main-safeguard-content bg-changer">
        <div className="main-left-section">
          <h1><strong>Going Forward</strong></h1>
          <div className="main-safeguard-product">
            <h2><strong></strong></h2>
            <p></p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="text-changer">
            <h2><strong>Takeaways</strong></h2>
            <h2><strong>Next Steps</strong></h2>
          </div>
        </div>
      </div>

      <div className="main-safeguard-content">
        <div className="main-left-section">
          <h1><strong>Takeaways</strong></h1>
          <div className="main-safeguard-product">
            <h2><strong>Impact</strong></h2>
            <p>
            The key takeaway from this project was the importance of designing secure, user-friendly delivery options that address package theft concerns while prioritizing clear communication and user satisfaction. By focusing on real user pain points, the project demonstrated how thoughtful design can build trust and improve overall experiences.
            </p>
          </div>
        </div>
        <div className="main-right-section">
          <div className="main-right-user-section">
            <h2><strong>What I learned</strong></h2>
            <p>
            From this project, I learned how to address real user pain points by applying empathetic, user-centered design practices. I gained valuable experience in creating secure delivery solutions and enhancing communication to build user trust and satisfaction. Additionally, I deepened my understanding of accessibility and inclusivity, ensuring designs catered to a diverse audience. This project reinforced the power of thoughtful UX design in solving tangible problems and improving overall user experiences.

            </p>
          </div>
        </div>
      </div>

      <div className="main-safeguard-content bg-changer">
        <div className="main-left-section">
          <h1><strong>Next Steps</strong></h1>
          <div className="main-safeguard-pain-point">
            <h2 className='text-changer'><strong>1</strong></h2>
            <p className='text-changer'>
            Conduct usability testing with a larger and more diverse group of users to gather additional insights and refine the solutions.
            </p>
          </div>
          <div className="main-safeguard-pain-point">
            <h2 className='text-changer'><strong>2</strong></h2>
            <p className='text-changer'>
            Engage with delivery services and e-commerce platforms to assess the feasibility of implementing the proposed designs.
            </p>
          </div>
          <div className="main-safeguard-pain-point">
            <h2 className='text-changer'><strong>3</strong></h2>
            <p className='text-changer'>
            Develop a detailed implementation plan and track the real-world impact of the solutions to ensure they effectively address user pain points and improve satisfaction.
            </p>
          </div>
        </div>
      </div>


      <div className="main-safeguard-content">
        <div className="main-left-section">
          <h1><strong>Let's connect</strong></h1>
          <div className="main-safeguard-pain-point">
            <h2><strong>Feedback</strong></h2>
            <p>
            I would appreciate your thoughts and insights on this topic, as feedback is crucial to further enhancing the findings of this study.
            </p>
          </div>
          <div className="main-safeguard-pain-point">
            <h2><strong>Email</strong></h2>
            <p>
            estelo2jz@gmail.com
            </p>
          </div>
          {/* <div className="main-safeguard-pain-point">
            <h2><strong>LinkedIn</strong></h2>
            <p>
            https://www.linkedin.com/in/estelo-abellanosa-83a335186/
            </p>
          </div> */}
        </div>
      </div>

    </div>
  )
}

export default Safeguard