import React from 'react';
import '../Styles/Proxy.scss'
import Hero from '../Hero';
import ProblemStatement from '../Problem';
import ProxyPNG from '../Styles/proxy.png'


const Proxy = () => {
  return (
    <div className="main-proxy-container">
      {/* <Hero img={ProxyPNG} /> */}
      <div className="main-proxy-content">
        <div className="left-section">
          <h1><strong>Project Overview</strong></h1>
          <div className="main-proxy-product">
            <h2><strong>The Product</strong></h2>
            <p>
              The project aims to understand and improve the online shopping experience by identifying pain points, particularly when users encounter fraudulent transactions. Through this, we seek to create trust, enhance user satisfaction, and develop preventive solutions.
            </p>
            <div className="project">
              <h2><strong>Project Duration</strong></h2>
              <p>
                Nov 2024 to Dec 2024
              </p>
            </div>
          </div>
        </div>
        <div className="right-section">
          <img src={ProxyPNG} alt="img" />

        </div>
      </div>


      <div className="main-proxy-content bg-changer">
        <div className="left-section">
          {/* <h1><strong>Project Overview</strong></h1> */}
          <div className="main-proxy-product text-changer">
            <h2 className='text-changer'><strong>The Problem</strong></h2>
            <p className='text-changer'>
              This project aimed to address the challenges of online shopping fraud, including the difficulty of identifying trustworthy websites, the absence of secure payment options, and inadequate post-purchase support. By tackling these issues, the goal was to enhance user trust, safety, and satisfaction in e-commerce platforms.
            </p>
          </div>
        </div>
        <div className="right-section text-changer">
          <div className="goal-section">
            <h2><strong>The Goal</strong></h2>
            <p>
              The goal of this project is to create a secure and user-friendly online shopping platform that builds trust and confidence by addressing common pain points, such as fraud prevention, accessible support, and transparent transaction processes. It aims to empower users with tools and resources for a safer shopping experience.
            </p>

          </div>
        </div>
      </div>

      <div className="main-proxy-content">
        <div className="left-section">
          {/* <h1><strong>Project Overview</strong></h1> */}
          <div className="main-proxy-product">
            <h2><strong>My Role</strong></h2>
            <p>
              As the UX designer leading the project, I take ownership of every aspect of the design process, from user research and prototyping to testing and refinement. Leveraging the power of Replit AI, I streamline development, ensure efficiency, and introduce innovative solutions that enhance user experiences while aligning with project goals and objectives.
            </p>
          </div>
        </div>
        <div className="right-section">
          <h2><strong>Responsibilies</strong></h2>
          <p>
            Conducting interviews, paper and digital wireframing, low and high-fidelity prototyping, conducting usability studies, accounting for accessibility, iterating on designs and responsive design.
          </p>
        </div>
      </div>


      <div className="main-proxy-content bg-changer">
        <div className="left-section">
          <h1><strong>Understanding The User</strong></h1>
          <div className="main-proxy-product">
            <h2><strong></strong></h2>
            <p>
            </p>
          </div>
        </div>
        <div className="right-section">
          <div className="text-changer">
            <h2><strong>User Research</strong></h2>
            <h2><strong>Personas</strong></h2>
            <h2><strong>Problems Statements</strong></h2>
            <h2><strong>User Journey Maps</strong></h2>

          </div>
        </div>
      </div>

      <div className="main-proxy-content">
        <div className="left-section">
          <h1><strong>User Research: Summary</strong></h1>
          <div className="main-proxy-product">
            <h2><strong></strong></h2>
            <p>
              For this project involved engaging a diverse group of participants, including scammed online shoppers, cautious buyers, frequent e-commerce users, and first-time shoppers. Through interviews, surveys, and usability testing, the research identified key pain points such as difficulty distinguishing fraudulent websites, lack of secure payment options, and insufficient post-purchase support. This process provided valuable insights into user behaviors, emotional responses, and expectations, forming the foundation for designing a safer and more trustworthy online shopping experience.
            </p>
          </div>
        </div>
      </div>

      <div className="main-proxy-content text-changer bg-changer">
        <div className="left-section">
          <h1><strong>User Research: Pain Points</strong></h1>
          <div className="main-proxy-pain-point">
            <h2><strong>Pain Point</strong></h2>
            <p>
              Difficulty Identifying trustworthy website, especially with convincing fake reviews

            </p>
          </div>
          <div className="main-proxy-pain-point">
            <h2><strong>Pain Point</strong></h2>
            <p>
              Fear of financial loss due to a lack of secure payment options
            </p>
          </div>
          <div className="main-proxy-pain-point">
            <h2><strong>Pain Point</strong></h2>
            <p>
              Frustration with unresponsive or helpful customer support when problems occur
            </p>
          </div>
        </div>
      </div>


      {/* <div className="main-proxy-content">
        <div className="left-section">
          <h1><strong>User Research: Summary</strong></h1>
          <div className="main-proxy-product">
            <h2><strong></strong></h2>
            <p>
              For this project involved engaging a diverse group of participants, including scammed online shoppers, cautious buyers, frequent e-commerce users, and first-time shoppers. Through interviews, surveys, and usability testing, the research identified key pain points such as difficulty distinguishing fraudulent websites, lack of secure payment options, and insufficient post-purchase support. This process provided valuable insights into user behaviors, emotional responses, and expectations, forming the foundation for designing a safer and more trustworthy online shopping experience.
            </p>
          </div>
        </div>
      </div> */}

      <div className="main-proxy-content ">
        <div className="left-section">
          <h1 cka><strong>Persona: David</strong></h1>
          <div className="main-proxy-product ">
            <h2 className=''><strong>Problem Statement</strong></h2>
            <p className=''>
              David struggles to trust online stores due to misleading designs, insecure payments, and poor support. She needs a safer, transparent shopping experience to regain confidence in e-commerce.
            </p>
          </div>
        </div>
        <div className="right-section">
          <div className="right-user-section">
            <h2 className=''><strong>David Smith</strong></h2>
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>


      <div className="main-proxy-content">
        <div className="left-section">
          <h1><strong>User Journey Map</strong></h1>
          <div className="main-proxy-product">
            <p>
              I created a user journey map of David’s experience using the site to help identify possible pain points and improvement opportunities.
            </p>
          </div>
        </div>
        <div className="right-section">
          <div className="right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>


      <div className="main-proxy-content bg-changer">
        <div className="left-section">
          <h1><strong>Starting The Design</strong></h1>
          <div className="main-proxy-product">
            <h2><strong></strong></h2>
            <p>
            </p>
          </div>
        </div>
        <div className="right-section">
          <div className="text-changer">
            <h2><strong>Sitemap</strong></h2>
            <h2><strong>Paper Wireframes</strong></h2>
            <h2><strong>Digital Wireframes</strong></h2>
            <h2><strong>Low-fidelity Prototype</strong></h2>
            <h2><strong>Usability Studies</strong></h2>
          </div>
        </div>
      </div>


      <div className="main-proxy-content">
        <div className="left-section">
          <h1><strong>Paper Wireframes</strong></h1>
          <div className="main-proxy-product">
            <h2><strong></strong></h2>
            <p>
              Focusing on the core featured identified during user research, I sketched the first wireframes using pen and paper.
            </p>
          </div>
        </div>
        <div className="right-section">
          <div className="right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>


      <div className="main-proxy-content bg-changer">
        <div className="left-section">
          <h1><strong>Paper Wireframes screeen size variatoin(s)</strong></h1>
          <div className="main-proxy-product">
            <h2><strong></strong></h2>
            <p className='text-changer'>I drafted iterations of each screen on paper.</p>
            <p className='text-changer'>
              I also started to work on designs for additional screen sizes to make sure the site would be fully responsive.
            </p>
          </div>
        </div>
        <div className="right-section">
          <div className="right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>

      <div className="main-proxy-content">
        <div className="left-section">
          <h1><strong>Sitemap</strong></h1>
          <div className="main-proxy-product">
            <h2><strong></strong></h2>
            <p>
              I built user-focused flows to ensure that my personas can successfully complete their key objectives while reducing the existing pain points.

            </p>
          </div>
        </div>
        <div className="right-section">
          <div className="right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>


      <div className="main-proxy-content bg-changer">
        <div className="left-section">
          <h1 className='text-changer'><strong>Digital Wireframes</strong></h1>
          <div className="main-proxy-product">
            <h2><strong></strong></h2>
            <p className='text-changer'>
              Using wireframes, I put my ideas on paper first and then started to make high-fidelity wireframes.
              After dozens of iterations, these are the wireframes that best represented user flow and met user needs.

            </p>
          </div>
        </div>
        <div className="right-section">
          <div className="right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>

      <div className="main-proxy-content">
        <div className="left-section">
          <h1><strong>Digital Wireframes screen size variations(s)</strong></h1>
          <div className="main-proxy-product">
            <h2><strong></strong></h2>
            <p>
              I also started to work on digital wireframes for additional screen sizes to make sure the site would be fully responsive.
            </p>
          </div>
        </div>
        <div className="right-section">
          <div className="right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>

      <div className="main-proxy-content bg-changer">
        <div className="left-section">
          <h1 className='text-changer'><strong>Low-fedility Prototype</strong></h1>
          <div className="main-proxy-product">
            <h2><strong></strong></h2>
            <p className='text-changer'>
              I created a low-fidelity prototype from the user flow diagram and wireframes to test functionality before incorporating it into final design and ensure accessibility for end-userss.
            </p>
          </div>
        </div>
        <div className="right-section">
          <div className="right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>


      <div className="main-proxy-content">
        <div className="left-section">
          <h1><strong>Usability Study: Parameters</strong></h1>
          <div className="main-proxy-pain-point">
            <h2><strong>Study Type:</strong></h2>
            <p>
              Unmoderated usability study
            </p>
          </div>
          <div className="main-proxy-pain-point">
            <h2><strong>Location:</strong></h2>
            <p>
              United States, remote
            </p>
          </div>
          <div className="main-proxy-pain-point">
            <h2><strong>Participants:</strong></h2>
            <p>
              F5 participants
            </p>
          </div>
          <div className="main-proxy-pain-point">
            <h2><strong>Length:</strong></h2>
            <p>
              20-30 minutes
            </p>
          </div>
        </div>
      </div>


      <div className="main-proxy-content bg-changer">
        <div className="left-section">
          <h1 className='text-changer'><strong>Usability Study: Parameters</strong></h1>
          <div className="main-proxy-pain-point">
            <h2 className='text-changer'><strong>Finding</strong></h2>
            <p className='text-changer'>
              Verified Seller Badges
              Users found the badges reassuring and were more likely to trust products with visible verification.

            </p>
          </div>
          <div className="main-proxy-pain-point">
            <h2 className='text-changer'><strong>Finding</strong></h2>
            <p className='text-changer'>
              Secure Payment Options
              Users appreciated the inclusion of secure payment methods and fraud protection labels.

            </p>
          </div>
          <div className="main-proxy-pain-point">
            <h2 className='text-changer'><strong>Finding</strong></h2>
            <p className='text-changer'>
              Trust Building Content
              Participants valued content like tips for spotting scams and platform transparency, but some felt the information could be more prominently displayed.

            </p>
          </div>
        </div>
      </div>

      <div className="main-proxy-content ">
        <div className="left-section">
          <h1><strong>Refining The Design</strong></h1>
          <div className="main-proxy-product">
            <h2><strong></strong></h2>
            <p>
            </p>
          </div>
        </div>
        <div className="right-section">
          <div className="right-user-section">
            <h2><strong>Mockups</strong></h2>
            <h2><strong>High-fedility Prototype</strong></h2>
            <h2><strong>Accessibility</strong></h2>
          </div>
        </div>
      </div>


      <div className="main-proxy-content bg-changer">
        <div className="left-section">
          <h1><strong>Mockups</strong></h1>
          <div className="main-proxy-product">
            <h2><strong></strong></h2>
            <p className='text-changer'>
              [Your notes about goals and thought process + how you responded to and implemented peer feedback]
            </p>
          </div>
          <img src={ProxyPNG} alt="img" />

        </div>
      </div>


      <div className="main-proxy-content">
        <div className="left-section">
          <h1><strong>Mockup: Original screen size</strong></h1>
          <div className="mocks">
            <img src={ProxyPNG} alt="img" />
            <img src={ProxyPNG} alt="img" />
            <img src={ProxyPNG} alt="img" />
            <img src={ProxyPNG} alt="img" />
          </div>
        </div>
      </div>

      <div className="main-proxy-content bg-changer">
        <div className="left-section">
          <h1><strong>High-Fedility Prototype</strong></h1>
          <div className="main-proxy-product">
            <h2><strong></strong></h2>
            <p className='text-changer'>
              After finalizing the low-fidelity prototype. I worked on creating the final designs with the goal of making them simple and intuitive. The main color theme i used was blue because it’s a very powerful color that symbolizes strength, power, and action. I thought it was very representative of the persona’s character.
            </p>
          </div>
        </div>
        <div className="right-section">
          <div className="right-user-section">
            <img src={ProxyPNG} alt="img" />

          </div>
        </div>
      </div>



      <div className="main-proxy-content">
        <div className="left-section">
          <h1><strong>Accessiblity Considerations</strong></h1>
          <div className="main-proxy-pain-point">
            <h2><strong>1</strong></h2>
            <p>
              Clear navigation include clear visual and text cues for navigation elements.

            </p>
          </div>
          <div className="main-proxy-pain-point">
            <h2><strong>2</strong></h2>
            <p>
              Readable text ensure high-contrast text for readability, especially for users with visual impairments.

            </p>
          </div>
          <div className="main-proxy-pain-point">
            <h2><strong>3</strong></h2>
            <p>
              Screen reader compatibility Ensure all images and icons have descriptive alt text
            </p>
          </div>
        </div>
      </div>

      <div className="main-proxy-content bg-changer">
        <div className="left-section">
          <h1><strong>Going Forward</strong></h1>
          <div className="main-proxy-product">
            <h2><strong></strong></h2>
            <p>
            </p>
          </div>
        </div>
        <div className="right-section">
          <div className="text-changer">
            <h2><strong>Takeaways</strong></h2>
            <h2><strong>Next Steps</strong></h2>
          </div>
        </div>
      </div>

      <div className="main-proxy-content">
        <div className="left-section">
          <h1><strong>Takeaways</strong></h1>
          <div className="main-proxy-product">
            <h2><strong>Impact</strong></h2>
            <p>
              The key takeaway for this project is the importance of building trust, ensuring security, and enhancing user experience in online shopping. The project highlights that users value clear verification processes, secure payment options, and effective post-purchase support. Addressing these core pain points not only prevents scams but also restores confidence in e-commerce platforms, making the shopping experience more reliable and user-friendly.
            </p>
          </div>
        </div>
        <div className="right-section">
          <div className="right-user-section">
            <h2><strong>What I learned</strong></h2>
            <p>
              Users need clear verification features (e.g., badges, secure payment options) to feel confident in their transactions. The project underscored that transparency and effective communication build trust.
            </p>
          </div>
        </div>
      </div>

      <div className="main-proxy-content bg-changer">
        <div className="left-section">
          <h1><strong>Next Steps</strong></h1>
          <div className="main-proxy-pain-point">
            <h2 className='text-changer'><strong>1</strong></h2>
            <p className='text-changer'>
              Refine Insights and objectives using the findings from research and usability studies to clearly define success metrics and priorities.
            </p>
          </div>
          <div className="main-proxy-pain-point">
            <h2 className='text-changer'><strong>2</strong></h2>
            <p className='text-changer'>
              Build key elements like verified seller badges, secure payment system, and intuitive help center


            </p>
          </div>
          <div className="main-proxy-pain-point">
            <h2 className='text-changer'><strong>3</strong></h2>
            <p className='text-changer'>
              Test and iterate conduct usability and accessibility testing, gathering user feedback to refine the platform.
            </p>
          </div>
        </div>
      </div>

      <div className="main-safeguard-content">
        <div className="main-left-section">
          <h1><strong>Let's connect</strong></h1>
          <div className="main-safeguard-pain-point">
            <h2><strong>Feedback</strong></h2>
            <p>
            I would appreciate your thoughts and insights on this topic, as feedback is crucial to further enhancing the findings of this study.
            </p>
          </div>
          <div className="main-safeguard-pain-point">
            <h2><strong>Email</strong></h2>
            <p>
            estelo2jz@gmail.com
            </p>
          </div>
          {/* <div className="main-safeguard-pain-point">
            <h2><strong>LinkedIn</strong></h2>
            <p>
            https://www.linkedin.com/in/estelo-abellanosa-83a335186/
            </p>
          </div> */}
        </div>
      </div>

    </div>
  )
}

export default Proxy